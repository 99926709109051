import React, { FunctionComponent } from "react";
import KritiqueRatings from "../KritiqueRatings";

export interface ProductRatingsInterface {
  upc: string;
  productName: string;
  template: string;
  summary?: boolean;
}

const ProductRatings: FunctionComponent<ProductRatingsInterface> = props => {
  return <KritiqueRatings {...props} />;
};

export default ProductRatings;
