import React, { FunctionComponent } from "react";

interface KritiqueRatingsInterface {
  upc: string;
  productName: string;
  template: string;
}

const  KritiqueRatings: FunctionComponent<KritiqueRatingsInterface> = ({
  productName = "",
  upc = "",
  template = ""
}) => {
  return (
    <div className="rx-rating" data-testid="ratings">
      <div
        className="rr-widget-container"
        data-summary-template={template}
        data-entity-type="product"
        data-parent-entity-id=""
        data-unique-id={upc}
        data-entity-id-type=""
        data-entity-url=""
        data-entity-name={productName}
        data-entity-image-url=""
      ></div>
    </div>
  );
};

export default KritiqueRatings;
