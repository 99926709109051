import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProductsType } from "./types";
import "./styles.scss";
import ProductRatings from "../ProductRatings";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { ImageInterface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Link } from "../Link";

const ProductsList = (props: ProductsType) => {
  const { language, sanityProductLandingPage, sanitySearch } = useContext(LocalizedContext);
  const { sanityId, sanityDataset, locale } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const { heading, showHeading, products = [] } = props;

  return (
    <div className="product-list product-slider">
      
      <Container>
        {heading && showHeading && <h2>{heading}</h2>}

        <Row>
          { products && products.map((product: any, index: number) => {
            const { images, slug, title, upc, _id } = product;
            const image = images[0] ? images[0] : (sanitySearch?._rawPlaceholder as ImageInterface);

            const url = `${sanityProductLandingPage?.slug.current}/${slug.current}`;

            return (
              <Col sm={6} md={4} key={`product-item-${index}`} className="product-list-item">
                <Link className="card-container" to={url as string} _id={_id} external={false}>
                  <div className="image-container">
                    <picture>
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(image?.asset)
                            .auto("format")
                            .quality(80)
                            .width(286)
                            .height(286)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <img
                        src={
                          urlBuilder
                            .image(image?.asset)
                            .auto("format")
                            .quality(80)
                            .width(265)
                            .height(265)
                            .format("webp")
                            .url() as string
                        }
                        alt={image?.alt}
                      />
                    </picture>
                  </div>
                  <hr />
                  <h2 className="title subhead">{title}</h2>
                </Link>
                <ProductRatings productName={title} upc={upc} template="pdp" />
              </Col>
            );
          }) }
        </Row>
      </Container>
    </div>
  );
};

export default ProductsList;
